const iconList = [
  { id: 1, name: 'el-icon-platform-eleme' },
  { id: 2, name: 'el-icon-eleme' },
  { id: 3, name: 'el-icon-delete-solid' },
  { id: 4, name: 'el-icon-delete' },
  { id: 5, name: 'el-icon-s-tools' },
  { id: 6, name: 'el-icon-setting' },
  { id: 7, name: 'el-icon-user-solid' },
  { id: 8, name: 'el-icon-user' },
  { id: 9, name: 'el-icon-phone' },
  { id: 10, name: 'el-icon-phone-outline' },
  { id: 11, name: 'el-icon-more' },
  { id: 12, name: 'el-icon-more-outline' },
  { id: 13, name: 'el-icon-star-on' },
  { id: 14, name: 'el-icon-star-off' },
  { id: 15, name: 'el-icon-s-goods' },
  { id: 16, name: 'el-icon-goods' },
  { id: 17, name: 'el-icon-warning' },
  { id: 18, name: 'el-icon-warning-outline' },
  { id: 19, name: 'el-icon-question' },
  { id: 20, name: 'el-icon-info' },
  { id: 21, name: 'el-icon-remove' },
  { id: 22, name: 'el-icon-circle-plus' },
  { id: 23, name: 'el-icon-success' },
  { id: 24, name: 'el-icon-error' },
  { id: 25, name: 'el-icon-zoom-in' },
  { id: 26, name: 'el-icon-zoom-out' },
  { id: 27, name: 'el-icon-remove-outline' },
  { id: 28, name: 'el-icon-circle-plus-outline' },
  { id: 29, name: 'el-icon-circle-check' },
  { id: 30, name: 'el-icon-circle-close' },
  { id: 31, name: 'el-icon-s-help' },
  { id: 32, name: 'el-icon-help' },
  { id: 33, name: 'el-icon-minus' },
  { id: 34, name: 'el-icon-plus' },
  { id: 35, name: 'el-icon-check' },
  { id: 36, name: 'el-icon-close' },
  { id: 37, name: 'el-icon-picture' },
  { id: 38, name: 'el-icon-picture-outline' },
  { id: 39, name: 'el-icon-picture-outline-round' },
  { id: 40, name: 'el-icon-upload' },
  { id: 41, name: 'el-icon-upload2' },
  { id: 42, name: 'el-icon-download' },
  { id: 43, name: 'el-icon-camera-solid' },
  { id: 44, name: 'el-icon-camera' },
  { id: 45, name: 'el-icon-video-camera-solid' },
  { id: 46, name: 'el-icon-video-camera' },
  { id: 47, name: 'el-icon-message-solid' },
  { id: 48, name: 'el-icon-bell' },
  { id: 49, name: 'el-icon-s-cooperation' },
  { id: 50, name: 'el-icon-s-order' },
  { id: 51, name: 'el-icon-s-platform' },
  { id: 52, name: 'el-icon-s-fold' },
  { id: 53, name: 'el-icon-s-unfold' },
  { id: 54, name: 'el-icon-s-operation' },
  { id: 55, name: 'el-icon-s-promotion' },
  { id: 56, name: 'el-icon-s-home' },
  { id: 57, name: 'el-icon-s-release' },
  { id: 58, name: 'el-icon-s-ticket' },
  { id: 59, name: 'el-icon-s-management' },
  { id: 60, name: 'el-icon-s-open' },
  { id: 61, name: 'el-icon-s-shop' },
  { id: 62, name: 'el-icon-s-marketing' },
  { id: 63, name: 'el-icon-s-flag' },
  { id: 64, name: 'el-icon-s-comment' },
  { id: 65, name: 'el-icon-s-finance' },
  { id: 66, name: 'el-icon-s-claim' },
  { id: 67, name: 'el-icon-s-custom' },
  { id: 68, name: 'el-icon-s-opportunity' },
  { id: 69, name: 'el-icon-s-data' },
  { id: 70, name: 'el-icon-s-check' },
  { id: 71, name: 'el-icon-s-grid' },
  { id: 72, name: 'el-icon-menu' },
  { id: 73, name: 'el-icon-share' },
  { id: 74, name: 'el-icon-d-caret' },
  { id: 75, name: 'el-icon-caret-left' },
  { id: 76, name: 'el-icon-caret-right' },
  { id: 77, name: 'el-icon-caret-bottom' },
  { id: 78, name: 'el-icon-caret-top' },
  { id: 79, name: 'el-icon-bottom-left' },
  { id: 80, name: 'el-icon-bottom-right' },
  { id: 81, name: 'el-icon-back' },
  { id: 82, name: 'el-icon-right' },
  { id: 83, name: 'el-icon-bottom' },
  { id: 84, name: 'el-icon-top' },
  { id: 85, name: 'el-icon-top-left' },
  { id: 86, name: 'el-icon-top-right' },
  { id: 87, name: 'el-icon-arrow-left' },
  { id: 88, name: 'el-icon-arrow-right' },
  { id: 89, name: 'el-icon-arrow-down' },
  { id: 90, name: 'el-icon-arrow-up' },
  { id: 91, name: 'el-icon-d-arrow-left' },
  { id: 92, name: 'el-icon-d-arrow-right' },
  { id: 93, name: 'el-icon-video-pause' },
  { id: 94, name: 'el-icon-video-play' },
  { id: 95, name: 'el-icon-refresh' },
  { id: 96, name: 'el-icon-refresh-right' },
  { id: 97, name: 'el-icon-refresh-left' },
  { id: 98, name: 'el-icon-finished' },
  { id: 99, name: 'el-icon-sort' },
  { id: 100, name: 'el-icon-sort-up' },
  { id: 101, name: 'el-icon-sort-down' },
  { id: 102, name: 'el-icon-rank' },
  { id: 103, name: 'el-icon-loading' },
  { id: 104, name: 'el-icon-view' },
  { id: 105, name: 'el-icon-c-scale-to-original' },
  { id: 106, name: 'el-icon-date' },
  { id: 107, name: 'el-icon-edit' },
  { id: 108, name: 'el-icon-edit-outline' },
  { id: 109, name: 'el-icon-folder' },
  { id: 110, name: 'el-icon-folder-opened' },
  { id: 111, name: 'el-icon-folder-add' },
  { id: 112, name: 'el-icon-folder-remove' },
  { id: 113, name: 'el-icon-folder-delete' },
  { id: 114, name: 'el-icon-folder-checked' },
  { id: 115, name: 'el-icon-tickets' },
  { id: 116, name: 'el-icon-document-remove' },
  { id: 117, name: 'el-icon-document-delete' },
  { id: 118, name: 'el-icon-document-copy' },
  { id: 119, name: 'el-icon-document-checked' },
  { id: 120, name: 'el-icon-document' },
  { id: 121, name: 'el-icon-document-add' },
  { id: 122, name: 'el-icon-printer' },
  { id: 123, name: 'el-icon-paperclip' },
  { id: 124, name: 'el-icon-takeaway-box' },
  { id: 125, name: 'el-icon-search' },
  { id: 126, name: 'el-icon-monitor' },
  { id: 127, name: 'el-icon-attract' },
  { id: 128, name: 'el-icon-mobile' },
  { id: 129, name: 'el-icon-scissors' },
  { id: 130, name: 'el-icon-umbrella' },
  { id: 131, name: 'el-icon-headset' },
  { id: 132, name: 'el-icon-brush' },
  { id: 133, name: 'el-icon-mouse' },
  { id: 134, name: 'el-icon-coordinate' },
  { id: 135, name: 'el-icon-magic-stick' },
  { id: 136, name: 'el-icon-reading' },
  { id: 137, name: 'el-icon-data-line' },
  { id: 138, name: 'el-icon-data-board' },
  { id: 139, name: 'el-icon-pie-chart' },
  { id: 140, name: 'el-icon-data-analysis' },
  { id: 141, name: 'el-icon-collection-tag' },
  { id: 142, name: 'el-icon-film' },
  { id: 143, name: 'el-icon-suitcase' },
  { id: 144, name: 'el-icon-suitcase-1' },
  { id: 145, name: 'el-icon-receiving' },
  { id: 146, name: 'el-icon-collection' },
  { id: 147, name: 'el-icon-files' },
  { id: 148, name: 'el-icon-notebook-1' },
  { id: 149, name: 'el-icon-notebook-2' },
  { id: 150, name: 'el-icon-toilet-paper' },
  { id: 151, name: 'el-icon-office-building' },
  { id: 152, name: 'el-icon-school' },
  { id: 153, name: 'el-icon-table-lamp' },
  { id: 154, name: 'el-icon-house' },
  { id: 155, name: 'el-icon-no-smoking' },
  { id: 156, name: 'el-icon-smoking' },
  { id: 157, name: 'el-icon-shopping-cart-full' },
  { id: 158, name: 'el-icon-shopping-cart-1' },
  { id: 159, name: 'el-icon-shopping-cart-2' },
  { id: 160, name: 'el-icon-shopping-bag-1' },
  { id: 161, name: 'el-icon-shopping-bag-2' },
  { id: 162, name: 'el-icon-sold-out' },
  { id: 163, name: 'el-icon-sell' },
  { id: 164, name: 'el-icon-present' },
  { id: 165, name: 'el-icon-box' },
  { id: 166, name: 'el-icon-bank-card' },
  { id: 167, name: 'el-icon-money' },
  { id: 168, name: 'el-icon-coin' },
  { id: 169, name: 'el-icon-wallet' },
  { id: 170, name: 'el-icon-discount' },
  { id: 171, name: 'el-icon-price-tag' },
  { id: 172, name: 'el-icon-news' },
  { id: 173, name: 'el-icon-guide' },
  { id: 174, name: 'el-icon-male' },
  { id: 175, name: 'el-icon-female' },
  { id: 176, name: 'el-icon-thumb' },
  { id: 177, name: 'el-icon-cpu' },
  { id: 178, name: 'el-icon-link' },
  { id: 179, name: 'el-icon-connection' },
  { id: 180, name: 'el-icon-open' },
  { id: 181, name: 'el-icon-turn-off' },
  { id: 182, name: 'el-icon-set-up' },
  { id: 183, name: 'el-icon-chat-round' },
  { id: 184, name: 'el-icon-chat-line-round' },
  { id: 185, name: 'el-icon-chat-square' },
  { id: 186, name: 'el-icon-chat-dot-round' },
  { id: 187, name: 'el-icon-chat-dot-square' },
  { id: 188, name: 'el-icon-chat-line-square' },
  { id: 189, name: 'el-icon-message' },
  { id: 190, name: 'el-icon-postcard' },
  { id: 191, name: 'el-icon-position' },
  { id: 192, name: 'el-icon-turn-off-microphone' },
  { id: 193, name: 'el-icon-microphone' },
  { id: 194, name: 'el-icon-close-notification' },
  { id: 195, name: 'el-icon-bangzhu' },
  { id: 196, name: 'el-icon-time' },
  { id: 197, name: 'el-icon-odometer' },
  { id: 198, name: 'el-icon-crop' },
  { id: 199, name: 'el-icon-aim' },
  { id: 200, name: 'el-icon-switch-button' },
  { id: 201, name: 'el-icon-full-screen' },
  { id: 202, name: 'el-icon-copy-document' },
  { id: 203, name: 'el-icon-mic' },
  { id: 204, name: 'el-icon-stopwatch' },
  { id: 205, name: 'el-icon-medal-1' },
  { id: 206, name: 'el-icon-medal' },
  { id: 207, name: 'el-icon-trophy' },
  { id: 208, name: 'el-icon-trophy-1' },
  { id: 209, name: 'el-icon-first-aid-kit' },
  { id: 210, name: 'el-icon-discover' },
  { id: 211, name: 'el-icon-place' },
  { id: 212, name: 'el-icon-location' },
  { id: 213, name: 'el-icon-location-outline' },
  { id: 214, name: 'el-icon-location-information' },
  { id: 215, name: 'el-icon-add-location' },
  { id: 216, name: 'el-icon-delete-location' },
  { id: 217, name: 'el-icon-map-location' },
  { id: 218, name: 'el-icon-alarm-clock' },
  { id: 219, name: 'el-icon-timer' },
  { id: 220, name: 'el-icon-watch-1' },
  { id: 221, name: 'el-icon-watch' },
  { id: 222, name: 'el-icon-lock' },
  { id: 223, name: 'el-icon-unlock' },
  { id: 224, name: 'el-icon-key' },
  { id: 225, name: 'el-icon-service' },
  { id: 226, name: 'el-icon-mobile-phone' },
  { id: 227, name: 'el-icon-bicycle' },
  { id: 228, name: 'el-icon-truck' },
  { id: 229, name: 'el-icon-ship' },
  { id: 230, name: 'el-icon-basketball' },
  { id: 231, name: 'el-icon-football' },
  { id: 232, name: 'el-icon-soccer' },
  { id: 233, name: 'el-icon-baseball' },
  { id: 234, name: 'el-icon-wind-power' },
  { id: 235, name: 'el-icon-light-rain' },
  { id: 236, name: 'el-icon-lightning' },
  { id: 237, name: 'el-icon-heavy-rain' },
  { id: 238, name: 'el-icon-sunrise' },
  { id: 239, name: 'el-icon-sunrise-1' },
  { id: 240, name: 'el-icon-sunset' },
  { id: 241, name: 'el-icon-sunny' },
  { id: 242, name: 'el-icon-cloudy' },
  { id: 243, name: 'el-icon-partly-cloudy' },
  { id: 244, name: 'el-icon-cloudy-and-sunny' },
  { id: 245, name: 'el-icon-moon' },
  { id: 246, name: 'el-icon-moon-night' },
  { id: 247, name: 'el-icon-dish' },
  { id: 248, name: 'el-icon-dish-1' },
  { id: 249, name: 'el-icon-food' },
  { id: 250, name: 'el-icon-chicken' },
  { id: 251, name: 'el-icon-fork-spoon' },
  { id: 252, name: 'el-icon-knife-fork' },
  { id: 253, name: 'el-icon-burger' },
  { id: 254, name: 'el-icon-tableware' },
  { id: 255, name: 'el-icon-sugar' },
  { id: 256, name: 'el-icon-dessert' },
  { id: 257, name: 'el-icon-ice-cream' },
  { id: 258, name: 'el-icon-hot-water' },
  { id: 259, name: 'el-icon-water-cup' },
  { id: 260, name: 'el-icon-coffee-cup' },
  { id: 261, name: 'el-icon-cold-drink' },
  { id: 262, name: 'el-icon-goblet' },
  { id: 263, name: 'el-icon-goblet-full' },
  { id: 264, name: 'el-icon-goblet-square' },
  { id: 265, name: 'el-icon-goblet-square-full' },
  { id: 266, name: 'el-icon-refrigerator' },
  { id: 267, name: 'el-icon-grape' },
  { id: 268, name: 'el-icon-watermelon' },
  { id: 269, name: 'el-icon-cherry' },
  { id: 270, name: 'el-icon-apple' },
  { id: 271, name: 'el-icon-pear' },
  { id: 272, name: 'el-icon-orange' },
  { id: 273, name: 'el-icon-coffee' },
  { id: 274, name: 'el-icon-ice-tea' },
  { id: 275, name: 'el-icon-ice-drink' },
  { id: 276, name: 'el-icon-milk-tea' },
  { id: 277, name: 'el-icon-potato-strips' },
  { id: 278, name: 'el-icon-lollipop' },
  { id: 279, name: 'el-icon-ice-cream-square' },
  { id: 280, name: 'el-icon-ice-cream-round' }
]
export default iconList
