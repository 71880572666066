<template>
  <div class="auth">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>帐号管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入路由标题" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" @click="addVisible = true">新增</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-table class="page-table" :data="dataList" border>
        <el-table-column prop="sort" label="排序" width="60"></el-table-column>
        <el-table-column prop="auth_type" label="类型" width="80">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.auth_type === 'api'">API</el-tag>
            <el-tag type="warning" v-if="scope.row.auth_type === 'menu'">菜单</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="权限名称" width="180"></el-table-column>
        <el-table-column prop="url" label="路由"></el-table-column>
        <el-table-column prop="method" label="请求方法">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.method === 'GET'">GET</el-tag>
            <el-tag type="success" v-if="scope.row.method === 'POST'">POST</el-tag>
            <el-tag type="info" v-if="scope.row.method === 'PUT'">PUT</el-tag>
            <el-tag type="warning" v-if="scope.row.method === 'DELETE'">DELETE</el-tag>
            <el-tag type="danger" v-if="scope.row.method === '*'">ANY *</el-tag>
            <el-tag type="info" v-if="scope.row.method === '0'">无</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="菜单图标"></el-table-column>
        <el-table-column prop="t_title" label="父级节点"></el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template fixed="right" slot-scope="scope">
            <el-button size="small" @click="editItem(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加权限路由弹窗 -->
    <el-dialog title="添加权限路由" :visible.sync="addVisible" width="30%" top="4vh" :before-close="addClose">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="top">
        <el-form-item label="权限类型" prop="type">
          <el-radio-group v-model="addForm.auth_type" size="small">
            <el-radio-button label="api"> API </el-radio-button>
            <el-radio-button label="menu"> 菜单 </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="addForm.sort" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="权限名称" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="路由" prop="url">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>
        <el-form-item label="请求方法" prop="method" v-show="addForm.auth_type === 'api'">
          <el-radio-group v-model="addForm.method" size="small">
            <el-radio-button label="GET">GET</el-radio-button>
            <el-radio-button label="POST">POST</el-radio-button>
            <el-radio-button label="PUT">PUT</el-radio-button>
            <el-radio-button label="DELETE">DELETE</el-radio-button>
            <el-radio-button label="*">ANY *</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单图标" prop="icon" v-show="addForm.auth_type === 'menu'">
          <el-input v-model="addForm.icon" :disabled="true">
            <el-button slot="append" icon="el-icon-circle-plus" @click="addIconVisible = true"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="父级节点" prop="parent">
          <el-select v-model="addForm.parent" placeholder="请选择">
            <el-option label="无(根节点)" :value="0"></el-option>
            <el-option v-for="item in parentList" :key="item.id" :value="item.id" :label="item.v0 + '--' + item.v2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addSubmit('addRef')">新增规则</el-button>
          <el-button @click="cancellAdd">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改权限路由弹窗 -->
    <el-dialog title="编辑权限路由" :visible.sync="editVisible" width="30%" top="4vh" :before-close="editClose">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="top">
        <el-form-item label="权限类型" prop="auth_type">
          <el-radio-group v-model="editForm.auth_type" size="small">
            <el-radio-button label="api"> API </el-radio-button>
            <el-radio-button label="menu"> 菜单 </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="editForm.sort" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="权限名称" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="路由" prop="url">
          <el-input v-model="editForm.url"></el-input>
        </el-form-item>
        <el-form-item label="请求方法" prop="method" v-show="editForm.auth_type === 'api'">
          <el-radio-group v-model="editForm.method" size="small">
            <el-radio-button label="GET">GET</el-radio-button>
            <el-radio-button label="POST">POST</el-radio-button>
            <el-radio-button label="PUT">PUT</el-radio-button>
            <el-radio-button label="DELETE">DELETE</el-radio-button>
            <el-radio-button label="*">ANY *</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单图标" prop="icon" v-show="editForm.auth_type === 'menu'">
          <el-input v-model="editForm.icon" :disabled="true">
            <el-button slot="append" icon="el-icon-circle-plus" @click="editIconVisible = true"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="父级节点" prop="parent">
          <el-select v-model="editForm.parent" placeholder="请选择">
            <el-option label="无(根节点)" :value="0">无</el-option>
            <el-option v-for="item in parentList" :key="item.id" :label="item.v0" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editSubmit('editRef')">修改规则</el-button>
          <el-button @click="cancellEdit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 图标选择弹窗(添加) -->
    <el-dialog title="提示" :visible.sync="addIconVisible" width="510px">
      <div class="icon-list">
        <div class="icon-item" v-for="item in iconList" :key="item.id" @click="addSelectIcon(item.name)">
          <i :class="item.name"></i>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </el-dialog>
    <!-- 图标选择弹窗（编辑） -->
    <el-dialog title="提示" :visible.sync="editIconVisible" width="510px">
      <div class="icon-list">
        <div class="icon-item" v-for="item in iconList" :key="item.id" @click="editSelectIcon(item.name)">
          <i :class="item.name"></i>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import iconList from '../../assets/js/iconList'
export default {
  name: 'Auth',
  data () {
    return {
      query: {
        title: ''
      },
      addIconVisible: false,
      editIconVisible: false,
      iconList: iconList,
      parentList: [],
      addForm: {
        sort: 0,
        auth_type: 'api',
        title: '',
        url: '',
        method: '*',
        icon: '',
        parent: 0
      },
      editForm: {
        id: 0,
        sort: 0,
        auth_type: 'api',
        title: '',
        url: '',
        method: '*',
        icon: '',
        parent: 0
      },
      addRules: {
        title: [
          { required: true, message: '请输入权限名称', trigger: 'blur' },
          { min: 2, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur' }
        ]
      },
      editRules: {
        title: [
          { required: true, message: '请输入权限名称', trigger: 'blur' },
          { min: 2, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur' }
        ]
      },
      dataList: [],
      // 添加弹窗
      addVisible: false,
      // 编辑弹窗
      editVisible: false
    }
  },
  created () {
    this.getDataList()
    this.getParentData()
  },
  methods: {
    async getParentData () {
      const { data: res } = await this.$http.get('all-parents')
      if (res.code === 200) {
        this.parentList = res.data
      }
    },
    // 选中图标
    addSelectIcon (name) {
      this.addForm.icon = name
      this.addIconVisible = false
    },
    editSelectIcon (name) {
      this.editForm.icon = name
      this.editIconVisible = false
    },
    // 获取权限规则列表
    async getDataList () {
      const { data: res } = await this.$http.get('auth-list', { params: this.query })
      if (res.code === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async editItem (id) {
      const { data: res } = await this.$http.get('auth/' + id)
      if (res.code === 200) {
        this.editForm.id = res.data.id
        this.editForm.sort = res.data.sort
        this.editForm.auth_type = res.data.auth_type
        this.editForm.title = res.data.v0
        this.editForm.url = res.data.v1
        this.editForm.method = res.data.v2
        this.editForm.icon = res.data.icon
        this.editForm.parent = res.data.parent
        this.editVisible = true
      }
    },
    async delItem (id) {
      const { data: res } = await this.$http.delete('auth/' + id)
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
        await this.getParentData()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭弹窗
    addClose (done) {
      this.$refs.addRef.resetFields()
      done()
    },
    // 关闭弹窗
    editClose (done) {
      this.$refs.editRef.resetFields()
      done()
    },
    // 添加规则
    addSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('auth/0', this.addForm)
          if (res.code === 200) {
            await this.getDataList()
            await this.getParentData()
            this.$refs.addRef.resetFields()
            this.addVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消添加规则
    cancellAdd () {
      this.$refs.addRef.resetFields()
      this.addVisible = false
    },
    // 编辑规则
    editSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('auth/' + this.editForm.id, this.editForm)
          if (res.code === 200) {
            await this.getDataList()
            await this.getParentData()
            this.$refs.editRef.resetFields()
            this.editVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消编辑规则
    cancellEdit () {
      this.$refs.editRef.resetFields()
      this.editVisible = false
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
.edit-form{
  padding: 0 40px;
  text-align: left;
}
.icon-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 500px;
  overflow-y: auto;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.icon-list::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: #d3dce6;
}

/*定义滚动条轨道 内阴影+圆角*/
.icon-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
.icon-list::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  background-color: rgb(19, 206, 102);
}
.icon-list .icon-item{
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  margin: 6px;
  border: 1px solid;
  padding: 10px;
  cursor: pointer;
}
.icon-list .icon-item i{
  font-size: 24px;
}
.icon-list .icon-item span{
  font-size: 12px;
  margin-top: 4px;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
</style>
